import React from "react"
import Layout from "../components/layout"

import Container from "react-bootstrap/Container"

const Impressum = () => {
  return (
    <Layout menueDistance>
      <Container fluid>
        <div className={`row greyback contentmodul`}>
          <div className="col-sm-12 col-md-4 order-1">
            <h2>Impressum</h2>
          </div>
          <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-center align-items-start order-3 order-md-2 mt-4 mt-md-0">
            <p>
              Q:marketing Aktiengesellschaft
              <br /> Düsseldorfer Straße 193
              <br /> 45481 Mülheim an der Ruhr
            </p>
            <p>
              Telefon +49 208 3015-0
              <br /> Telefax +49 208 3015-555
              <br /> E-Mail:{" "}
              <a title="Kontakt aufnehmen" href="mailto:dialog@Qmarketing.de">
                dialog@Qmarketing.de
              </a>
            </p>
            <p>
              Vorstand:
              <br /> Hanns-Peter Windfeder, Christian Windfeder, Jürgen Dworak
            </p>
            <p>
              HRB 15892
              <br /> Amtsgericht Duisburg
            </p>
            <p>
              Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:
              <br /> DE 210806670
            </p>
            <h3>Streitschlichtung</h3>
            <p>
              Die Europäische Kommission stellt eine Plattform zur
              Online-Streitbeilegung (OS) bereit:
              https://ec.europa.eu/consumers/odr.
              <br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
              <br /> Wir sind nicht bereit oder verpflichtet, an
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              teilzunehmen.
            </p>
            <h3>Haftung für Inhalte</h3>
            <p>
              Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
              <br /> Für die Richtigkeit, Vollständigkeit und Aktualität der
              Inhalte können wir jedoch keine Gewähr übernehmen. Als
              Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
              auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
              Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
              verpflichtet, übermittelte oder gespeicherte fremde Informationen
              zu überwachen oder nach Umständen zu forschen, die auf eine
              rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung
              oder Sperrung der Nutzung von Informationen nach den allgemeinen
              Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung
              ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
              Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
              Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>
            <h3>Haftung für Links</h3>
            <p>
              Unser Angebot enthält Links zu externen Webseiten Dritter, auf
              deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
              diese fremden Inhalte auch keine Gewähr übernehmen. Für die
              Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
              überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
              Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle
              der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
              Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
              Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </p>
            <h3>Urheberrecht</h3>
            <p>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen dem deutschen Urheberrecht. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht
              kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
              Seite nicht vom Betreiber erstellt wurden, werden die
              Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
              Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
              entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
              werden wir derartige Inhalte umgehend entfernen.
            </p>
            <p>
              Quelle:{" "}
              <a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
            </p>
            <p>
              <a
                href="/datenschutz/"
                class="inner-link"
              >
                Hinweise zum Datenschutz finden Sie hier.
              </a>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default Impressum
